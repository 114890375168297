import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/zA1juM6red8">
    <SEO title="Lazy Christianity - Hebrews" />
  </Layout>
)
export default SermonPost
